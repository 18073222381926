@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.header {
  display: flex;
  width: 100%;
  position: sticky;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 2;
  border-radius: 0;
  padding: 12px 14px;

  @include mainContentStyles;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  .title {
    display: flex;
    width: 100%;
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-cards-category-title-default);

    @include min-428-break {
      @include typography-m;
    }
  }
}
