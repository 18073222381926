@use '@fixed-size' as *;
@use '@queries' as *;

.swiper-mobile,
.swiper-desktop {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  @include min-428-break {
    gap: 14px;
  }

  .swiper-wrapper {
    width: inherit;
    height: inherit;
    display: flex;

    .swiper-slide {
      width: inherit;
      height: inherit;
      padding: 0 12px;

      @include min-744-break {
        padding: 0 6px;
      }
    }
  }

  .swiper-pagination {
    position: initial;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: flex-start;
    min-height: 8px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: #dcdcdc;
      margin: 0;
    }

    .swiper-pagination-bullet-active {
      background: var(--cst-indicators-on);
      box-shadow: 0px 0px 0px 2px rgba(#6941c6, 0.2);
    }
  }
}
.swiper-desktop {
  .swiper-wrapper {
    .swiper-slide {
      @include min-1024-break {
        padding: 1px;
      }
    }
  }

  .swiper-pagination {
    display: none;
  }
}
.swiper-live-feed {
  .swiper-wrapper {
    //transition-timing-function: linear;
  }
}
