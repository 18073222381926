@use '@queries' as *;
@use '@content' as *;

.questions {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 12px 14px;
  @include mainContentStyles;
  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}
