@use '@content' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
  max-width: 1698px;
  min-width: 1024px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  padding: 0 20px;

  @include min-1440-break {
    padding: 0 40px;
  }

  &:has(div[id='policies']),
  &:has(div[id='drop-page']),
  &:has(div[id='faq']) {
    padding: 0;
    max-width: 100%;
  }
}

.body,
.body-mobile {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100dvw;
  height: 100%;
  flex: 1;
  @include hideScrollbar;

  &:has(div[id='sidebar--opened']) {
    overflow: hidden;
    height: 100dvh;
  }
}

.body-mobile {
  min-width: 320px;
  height: 100dvh;
  max-height: 100dvh;

  .scrollable-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &:has(* > div[id='cart-empty']),
    &:has(* > div[id='favorite-empty']) {
      footer[id='mobile-footer'] {
        display: none;
      }
    }

    @include hideScrollbar;

    .mobile-container {
      display: flex;
      flex: 1;
      min-height: max-content;
      width: 100%;
      background: var(--global-grey300);
    }
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
}

.sidebar {
  display: flex;
  width: 100%;
  background: red;
  position: absolute;
  flex: 1;
  min-height: 100%;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
